<template>
  <v-app>
    <v-main>
      <form>
        <v-container grid-list-md class="ghost">
          <v-row>
            <v-col class="text-center">
              <span v-html="appHeader.html"></span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <br />
              <v-divider />
              <br />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-btn to="/regular" color="#e2f0d9" class="black--text" x-large>
                Regular Eyes
              </v-btn>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn to="/complex" color="#fbe5d6" class="black--text" x-large>
                Complex Eyes
              </v-btn>
              <br />
              <br />
              <ul>
                <li>Post myopic Laser Vision Correction</li>
                <li>Post Hyperopic Laser Vision Correction</li>
                <li>Post Radial Keratotomy</li>
                <li>Post Implantable Collamer Lens (ICL)</li>
                <li>Non-physiological cornea</li>
              </ul>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn to="/second" color="#dae3f3" class="black--text" x-large>
                Second Eyes
              </v-btn>
              <br />
              <br />
              <p class="text-center">(with information regarding first eye surgery)</p>
              <p class="text-justify">
                Enter the preoperative biometry and the resulting postoperative spherical equivalent of the first operated eye to obtain a more precise result of the IOL power of the second eye.
                <br><br>The second eye function is available for non-operated eyes and post-myopic, hyperopic laser vision correction.
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <br />
              <v-divider />
              <br />
            </v-col>
          </v-row>
        </v-container>
      </form>
    </v-main>
  </v-app>
</template>

<style>
.v-application .primary--text {
  color: #3b4256 !important;
  caret-color: #3b4256 !important;
}
</style>

<script>
import { mapState } from 'vuex'
import Logout from '@/components/Logout.vue'

export default {
  components: {
    Logout
  },
  computed: {
    ...mapState(['appHeader', 'ginputs'])
  }
}
</script>
